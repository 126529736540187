/* Customize the Clustered Markers */
.marker-cluster-custom {
    background: #95D66A;
    border: 3px solid #ededed;
    border-radius: 50%;
    color: #ededed;
    height: 40px;
    line-height: 37px;
    text-align: center;
    width: 40px;
}
